import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div className="content">
        <h1>NIE ZNALEZIONO STRONY</h1>
      <p>Strona, której szukasz, nie istnieje :(</p>
          <Link className="btn is-link" to="/">
            Powrót
          </Link>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
